import React from "react";
import "./AppHeader.css";
import logo from "../logo.svg";

import { HamburgerMenu } from "./HamburgerMenu";
import { useNavigationContext } from "../navigationContext";

export const AppHeader = () => {
  const [navState, updateNav] = useNavigationContext("sideBar");

  return (
    <header className="app-header mg-theme-default">
      <div className="mg-row mg-x--around">
        <HamburgerMenu
          onClick={() => updateNav({ isOpen: !navState.isOpen })}
          isActive={navState.isOpen}
        />
        <nav className="mg-nav mg-nav--inline " data-toogle="nav">
          <ul>
            <li>
              <a href="/">
                <img className="mg-icon" src={logo} alt="" />
                <span>ASSUPILOT</span>
              </a>
            </li>
          </ul>
        </nav>
        <nav className="mg-nav mg-nav--inline " data-toogle="nav">
          <ul>
            <li className="active">
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#news">News</a>
            </li>
            <li className="mg-right">
              <a href="#about">Login</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>

    // <header className="app-header">
    //   <nav className="navbar navbar-expand-lg static-top nav-bemo">
    //     <div className="mg-col">
    //       <div
    //         className="mg-nav mg-nav--inline"
    //         id="nav-horizontal-example"
    //         data-toggle="nav"
    //       >

    //       </div>
    //     </div>
    //   </nav>
    // </header>
  );
};
