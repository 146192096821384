import React from "react";
import "./ServiceCard.css";
import logo from "../logo.svg";
export default function ServiceCard(props: {
  title: string;
  icon: string;
  link: string;
}) {
  return (
    <div className="card-container mg-x6 mg-m3">
      <a href={`/devis/${props.link}`}>
        <div className="card-small">
          <h5>{props.title}</h5>
          <img src={`./${logo}`} width={77} alt=""></img>
          <a href={props.link}>
            <h6>Détails</h6>
          </a>
        </div>
      </a>
    </div>
  );
}
