import React from "react";
import "./AppSidebar.css";

import { useNavigationContext } from "../navigationContext";

export const AppSidebar = () => {
  const [sideBarState] = useNavigationContext("sideBar");

  return (
    <nav
      className={`mg-theme-inverted app-sidebar App-spliter App-spliter-left ${
        !sideBarState.isOpen ? "" : "active"
      } `}
    >
      <div className="app-sidebar-content">
        <div className="app-sidebar-menu">
          <div className="mg-nav" id="nav-vertical-example" data-toggle="nav">
            <ul>
              <li className="active">
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#news">News</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
