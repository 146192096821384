import { createGlobalState } from "react-hooks-global-state";

type State = {
  sideBar: { isOpen: boolean };
  navigation: { currentPage: string };
};
let initialState: State = {
  sideBar: { isOpen: false },
  navigation: { currentPage: "" },
};
const { useGlobalState } = createGlobalState(initialState);

export const useNavigationContext = useGlobalState;
