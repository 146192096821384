import "./HamburgerMenu.css";
import React from "react";

type Props = {
  onClick?: (event?: any) => void;
  isActive: boolean;
};
export const HamburgerMenu = (props: Props) => {
  function toggle(event: any) {
    if (props.onClick) props.onClick(event);
  }
  return (
    <div
      className={`hamburger hamburger--slider ${
        props.isActive ? "is-active" : ""
      } `}
      onClick={(ev) => toggle(ev)}
    >
      <div className="hamburger-box">
        <div className="hamburger-inner"></div>
      </div>
    </div>
  );
};
