import React, { useEffect, useState } from "react";
import { AppHeader } from "./components/AppHeader";
import { AppSidebar } from "./components/AppSidebar";
import ServiceCard from "./components/ServiceCard";
import "./App.css";
declare type AppConfig = {
  DeveloperPortalUrl: string;
  EnrollmentPortalUrl: string;
  CmsServerUri: string;
  SourceFileHost: string;
};

export const appConfig = (): AppConfig => {
  return (window as any).appConfig || {};
};

function App() {
  const [, setMessage] = useState("");
  useEffect(() => {
    fetch("/api/get-message?name=Static Web Apps")
      .then((res) => res.text())
      .then((data) => setMessage(data));
  }, []);
  return (
    <div className="App">
      <AppHeader></AppHeader>
      <AppSidebar></AppSidebar>
      <div className="App-spliter App-spliter-right mg-col mg-90vh mg-x--middle">
        <img
          alt="tenantLogo"
          src="https://www.redassurances.com/red-assurances-logo.svg"
          height={40}
        ></img>
        <div className="mg-row mg-l6 mg-l8 mg-m--offset4 mg-l--offset3">
          <ServiceCard title="Clients" icon="" link=""></ServiceCard>
          <ServiceCard title="Prospects" icon="" link=""></ServiceCard>
          <ServiceCard title="Contrats" icon="" link=""></ServiceCard>
          <ServiceCard title="Produits" icon="" link=""></ServiceCard>
        </div>
      </div>
    </div>
  );
}

export default App;
